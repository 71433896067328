import React from 'react'
import styled from 'styled-components'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const Content = styled.div`
  * {
    word-break: break-word;
  }

  h3 {
    font-size: 3.3rem;
    line-height: normal;
  }

  h4 {
    font-size: 1.867rem;
    line-height: normal;
  }

  h5 {
    font-size: 1.333rem;
  }

  @media only screen and (max-width: 500px) {
    h3 {
      font-size: 2.3rem;
    }
    h4 {
      font-size: 1.333rem;
    }
    h5 {
      font-size: 1rem;
    }
  }
`

const Hyperlink = styled.a`
  text-decoration: none;
  color: var(--deep-red);
`

const Paragraph = styled.p`
  margin: 28px auto;
`

const Image = styled.img`
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  max-width: 100%;
  border-radius: 32px;
`

const Video = styled.iframe`
  width: 100%;
`

const ImageTag = ({ children }) => (
  <Image src={children.file['en-US'].url} alt={children.title['en-US']} />
)

const Text = ({ children }) => <Paragraph>{children}</Paragraph>

const OPTIONS = {
  renderMark: {
    [MARKS.BOLD]: text => <b>{text}</b>
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [INLINES.HYPERLINK]: children =>
      (children.data.uri.match(/youtu\.?be/g) ? (
        <Video
          title={children.content[0].value}
          width="1120"
          height="630"
          src={`https://www.youtube.com/embed/${children.data.uri
            .split('/')
            .pop()}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      ) : (
        <Hyperlink href={children.data.uri}>
          {children.content[0].value}
        </Hyperlink>
      )),
    [BLOCKS.EMBEDDED_ASSET]: node => (
      <ImageTag>{node.data.target.fields}</ImageTag>
    )
  }
}

function Body ({ pageContext }) {
  return (
    <Content>{documentToReactComponents(pageContext.json, OPTIONS)}</Content>
  )
}

export default Body
