import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;

  :hover h3 {
    color: var(--deep-red);
  }
`

const Portrait = styled.img`
  display: inline-block;
  margin-right: 12px;
  max-height: 150px;
  border-radius: 50%;
`

const Text = styled.h3`
  text-transform: uppercase;
  font-size: 1.333rem;
  transition: color 240ms;
`

function Author ({ pageContext }) {
  return (
    <StyledLink
      to={`/author/${pageContext.content[0].data.target.fields.slug.en_US}`}
    >
      <Portrait
        src={
          pageContext.content[0].data.target.fields.portrait.en_US.fields.file
            .en_US.url
        }
        alt="Portrait"
      />
      <Text>
        ABOUT AUTHOR: {pageContext.content[0].data.target.fields.name.en_US}
      </Text>
    </StyledLink>
  )
}

export default Author
