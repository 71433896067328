import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import ClickableImage from '../components/clickable-image'
import Date from '../components/date'
import Views from '../components/views'
import Body from '../components/body'
import Author from '../components/author'
import SEO from '../components/seo'
// import RecentPosts from '../components/post-container'

const Header = styled.div`
  position: relative;
  width: 100vw;
  max-height: 400px;
  margin: 0 auto 128px auto;
  z-index: -1;

  img {
    filter: brightness(0.6) contrast(0.76);
    max-height: 400px;
  }

  @media only screen and (max-width: 768px) {
    margin: 124px auto 24px auto;
  }
`

const TitleBox = styled.div`
  position: absolute;
  top: 5%;
  display: flex;
  flex-direction: column;
  height: 95%;
  width: 100%;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 4.5em;
    text-align: center;

    @media only screen and (max-width: 1024px) {
      font-size: 3.3rem;
    }

    @media only screen and (max-width: 500px) {
      font-size: 2.3rem;
    }
  }

  h1,
  p {
    color: white;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;

  :hover {
    color: white;
  }

  ::after {
    content: " | ";
    color: white;
  }
`

const GraySection = styled.div`
  background: var(--bg-gray);
  padding: 32px 12px;
  width: calc(100% + 64px);
  margin-left: -32px;
  margin-bottom: -32px;

  h2 {
    margin-top: 72px;
    font-size: 4.667rem;
    line-height: normal;
  }

  @media only screen and (max-width: 768px) {
    padding: 32px 24px;
    margin-left: -64px;
    margin-bottom: -64px;
    width: calc(100% + 128px);

    h2 {
      font-size: 2.3rem;
    }
  }
`

export default ({ pageContext }) => (
  <div>
    <SEO
      title={pageContext.title || 'Blog'}
      keywords={[
        'bodytunes',
        'ems',
        'ems-training',
        'ems-studio',
        'fitness',
        'blog'
      ]}
    />
    {pageContext.headerImage && (
      <Header>
        <Img fluid={pageContext.headerImage.fluid} alt="Header Image" />
        <TitleBox>
          <h1>{pageContext.title.toUpperCase()}</h1>
          <p>
            <StyledLink to="/">Home</StyledLink>
            {pageContext.title}
          </p>
        </TitleBox>
      </Header>
    )}

    <Layout>
      <ClickableImage pageContext={pageContext.clickableImage} />

      <div>
        <Date pageContext={pageContext.postDate} />
        {/* <Views /> */}
      </div>

      <Body pageContext={pageContext.body} />

      <GraySection>
        <Author pageContext={pageContext.author} />

        {/* <div>
          <h2>RECENT POSTS</h2>
          <RecentPosts pageContext={pageContext.relatedPosts} />
        </div> */}
      </GraySection>
    </Layout>
  </div>
)
