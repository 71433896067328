import React from 'react'
import styled from 'styled-components'

const MONTHS = [
  'Januar',
  'Februar',
  'Marz',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember'
]

const DateBox = styled.span`
  font-size: 0.8em;
`

function Date ({ pageContext }) {
  return (
    <DateBox>
      {`${pageContext.split('-')[2]}. ${
        MONTHS[parseInt(pageContext.split('-')[1] - 1, 10)]
      } ${pageContext.split('-')[0]}`}
    </DateBox>
  )
}

export default Date
