import React from 'react'
import styled from 'styled-components'

const ViewBox = styled.span`
  margin: 0 12px;
  font-size: 0.8em;

  ::before {
    content: " | ";
    margin-right: 12px;
  }
`

function Views ({ pageContext }) {
  return <ViewBox>687 views</ViewBox>
}

export default Views
