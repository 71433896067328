import React from 'react'
import ImageZoom from 'react-medium-image-zoom'

function ClickableImage ({ pageContext }) {
  return (
    <div>
      <ImageZoom
        image={{
          src: `${pageContext.fluid.src}`,
          alt: `${pageContext.description}`,
          style: {
            width: 'calc(100% + 64px)',
            marginLeft: '-32px',
            marginTop: '-32px'
          }
        }}
      />
    </div>
  )
}

export default ClickableImage
